import React, { useEffect, useRef } from 'react'

import { css, Global, keyframes, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import FocusLock from 'react-focus-lock'
import { useTranslation } from 'react-i18next'

import { breakpoints, Button, Icon, spacing } from '@cash-design-system/react'
import logger from '@cash-web/shared/util-logger'
import { useLogEventstream } from '@cash-web/shared-feature-analytics'

import { ModalContextProvider } from './ModalContext'

type ModalVariant = 'default' | 'receipt' | 'fullscreen'

type DialogProps = {
  disableAnimation?: boolean
  width?: string
  isHelpAction?: boolean
  closable?: boolean
  fixed?: boolean
  variant?: ModalVariant
}

export const Position = styled.div`
  z-index: 99999;
  position: fixed;
  inset: 0 0 0 0;
`

const fadeIn = keyframes`
    0% {
      background: rgba(0, 0, 0, 0);
    }
    100% {
      background: rgba(0, 0, 0, 0.45);
    }
`

export const DialogScrollableBackground = styled.div<DialogProps>`
  ${breakpoints.tablet} {
    ${props =>
      !props.disableAnimation &&
      css`
        animation: ${fadeIn} 200ms forwards;
      `}
    ${props =>
      props.isHelpAction &&
      css`
        border-radius: 1.5rem;
      `}
    background: rgb(0 0 0 / 45%);
    padding: ${spacing.defaultContent};
    filter: opacity(0.99999);
    position: fixed;
    overflow-y: auto;
    inset: 0 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    ${({ fixed }) =>
      fixed &&
      css`
        align-items: start;
      `}
    ${({ variant, theme: { spacing } }) =>
      variant !== 'receipt' &&
      css`
        padding: ${spacing.xxxl} ${spacing.empty};
      `}
  }
`

const popUp = keyframes`
    0% {
        transform: translateY(1rem);
    }
    100% {
        transform: translateY(0);
    }
`

export const Dialog = styled.div<DialogProps>`
  position: relative;
  background: ${props => props.theme.colors.cardBackground};
  min-height: 100vh;
  max-height: 100vh;
  ${({ variant, theme: { spacing } }) =>
    variant !== 'receipt' &&
    css`
      padding: ${spacing.xl};
    `}
  display: flex;
  flex-direction: column;

  &:focus-visible {
    outline: none;
  }

  ${breakpoints.tablet} {
    ${props =>
      !props.disableAnimation &&
      css`
        animation: ${popUp} 200ms forwards;
      `}
    width: ${props => props.width};
    box-shadow: 0 12px 24px 0 #0000000a;
    border-radius: 1.5rem;
    ${({ variant, closable }) =>
      variant !== 'receipt' &&
      css`
        padding: ${closable === false ? '3.5rem 2.5rem' : '2.5rem 2.5rem 3.5rem 2.5rem'};
      `}

    min-height: ${({ variant }) => (variant === 'fullscreen' ? `unset` : `auto`)};
    ${({ variant }) => variant !== 'fullscreen' && `max-height: fit-content;`}
    ${({ fixed }) =>
      !fixed &&
      css`
        margin: auto 0;
      `}
    ${({ variant }) =>
      variant === 'fullscreen' &&
      css`
        inset: 2rem;
        position: absolute;
      `}
  }
  ${({ variant, theme: { spacing } }) =>
    variant === 'receipt' &&
    css`
      overflow: hidden;
      ${breakpoints.tablet} {
        padding: ${spacing.empty};
      }
    `}
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
`

const ModalContent = styled.div<{ overflowScroll: boolean }>`
  ${({ overflowScroll }) => {
    return (
      overflowScroll &&
      css`
        overflow-y: auto;
        min-height: 0;
        flex: 1 1 auto;
      `
    )
  }}
`

const CloseButtonWrapper = styled.div<{ variant?: ModalVariant }>`
  ${({ variant, theme: { spacing } }) =>
    variant === 'receipt'
      ? css`
          ${breakpoints.tablet} {
            padding: 2.5rem 2rem 0 2.5rem;
          }
        `
      : css`
          margin-right: -${spacing.xs};
        `}
`

const BackButtonWrapper = styled.div`
  transform: rotate(180deg);
  margin-left: -${({ theme }) => theme.spacing.xs};
`

export interface ModalProps extends DialogProps {
  onClose?: () => void
  onBack?: () => void
  closable?: boolean
  children?: React.ReactNode
  overflowScroll?: boolean
}

export const BodyOverFlowHidden = css`
  body {
    overflow: hidden;
  }
`

export const useModal = ({ onClose }: { onClose?: () => void }) => {
  const closeOnEscape: React.KeyboardEventHandler<HTMLDivElement> = event => {
    if (event.key === 'Escape') {
      onClose?.()
    }
  }

  return { closeOnEscape }
}

export const ModalActionHeader = ({
  variant,
  onClose,
  onBack,
}: {
  variant?: ModalVariant
  onClose?: () => void
  onBack?: () => void
}) => {
  const { t } = useTranslation()
  const { colors } = useTheme()

  return (
    <Header>
      <CloseButtonWrapper variant={variant}>
        <Button
          type="button"
          aria-label={t('Close modal', { context: 'aria label on a button' })}
          onClick={onClose}
          variant="tertiary"
          size="icon"
        >
          <Icon name="x" width="2rem" height="2rem" color={colors.label} />
        </Button>
      </CloseButtonWrapper>
      {onBack && (
        <BackButtonWrapper>
          <Button
            type="button"
            aria-label={t('Go back', { context: 'aria label to go back to the last screen' })}
            onClick={onBack}
            variant="tertiary"
            size="icon"
          >
            <Icon name="chevron-right" width="2rem" height="2rem" color={colors.label} />
          </Button>
        </BackButtonWrapper>
      )}
    </Header>
  )
}

const WIDTHS: Record<ModalVariant, string> = {
  default: '31rem',
  receipt: '23rem',
  fullscreen: 'unset',
}

export const Modal = ({
  children,
  onClose,
  onBack,
  closable = true,
  disableAnimation = false,
  variant = 'default',
  width = WIDTHS[variant],
  overflowScroll = false,
  isHelpAction,
  fixed = false,
}: ModalProps) => {
  const { closeOnEscape } = useModal({ onClose })
  const logEvent = useLogEventstream()

  const hasLoggedShowModalRef = useRef(false)
  useEffect(() => {
    if (!hasLoggedShowModalRef.current) {
      // This mimics the same ES2 event in Ember except that we are not also recording a "modal name" like Ember does.
      logEvent({ action: 'Open Modal' })
      hasLoggedShowModalRef.current = true
    }
  }, [logEvent])

  return (
    <>
      <Global styles={BodyOverFlowHidden} />
      <Position>
        <DialogScrollableBackground
          data-testid="modal-background"
          onClick={() => {
            if (closable) {
              logger.addBreadcrumb('Close modal')
              onClose?.()
            }
          }}
          disableAnimation={disableAnimation}
          isHelpAction={isHelpAction}
          fixed={fixed}
          variant={variant}
        >
          {/* we don't want any clicks on the dialog to propagate and force the window closed */}
          <FocusLock
            as={Dialog}
            lockProps={{
              onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation(),
              onKeyDown: closeOnEscape,
              role: 'dialog',
              disableAnimation: disableAnimation,
              width: width,
              fixed: fixed,
              ariaModal: true,
              closable,
              variant,
            }}
          >
            {closable ? <ModalActionHeader onClose={onClose} onBack={onBack} variant={variant} /> : null}
            <ModalContent overflowScroll={overflowScroll}>
              <ModalContextProvider>{children}</ModalContextProvider>
            </ModalContent>
          </FocusLock>
        </DialogScrollableBackground>
      </Position>
    </>
  )
}

import { useMemo } from 'react'

import { useRouter } from 'next/router'

import { fallbackLng as defaultLocale } from '../../next-i18next.config'
import { getPublicRuntimeConfig } from '../config/nextConfig'
import { defaultRegion } from '../config/regions'

const { frontendServiceUrl } = getPublicRuntimeConfig()

/**
 * Helper function to replace all occurrences of a pattern in a string,
 * as `replaceAll` is not supported in some browsers.
 *
 * @returns {string} - The resulting string after replacements.
 */
const replaceAll = (str: string, find: RegExp, replace: string) => {
  return str.split(find).join(replace)
}

/**
 * Generate a canonical URL
 * @returns A URL that can be used for link rel="canonical", with the default region and locale removed, and region preceding the locale, as that's what's used on the public frontend
 */
const useCanonicalURL = (...pathSections: string[]) => {
  const router = useRouter()

  return useMemo(() => {
    // On the public facing site, locale is after the region in the URL.

    // TODO cameronandrews janec Move locale to a dynamic route after region so it can be after region on both frontend and locale
    const url = [
      `${frontendServiceUrl}${router.basePath}`,
      router.query['region']?.toString()?.toLowerCase(),
      router.locale,
      ...pathSections,
    ]
      .filter(Boolean)
      .join('/')

    const cleanedUrl = replaceAll(url, new RegExp(`/(?:${defaultRegion}|${defaultLocale}|default)(?=/|$)`, 'gi'), '')

    return cleanedUrl
  }, [router.basePath, router.locale, router.query, pathSections])
}

export default useCanonicalURL

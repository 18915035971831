import * as React from 'react'

import { SerializedStyles, Theme } from '@emotion/react'
import styled from '@emotion/styled'

import { AlertFillIcon, AlertOutlineIcon, ArcadeIcon, InformationFillIcon } from '@arcade/web/icons/react'

import { getErrorLinkStyle, getLinkStyle, getWarningLinkStyle } from '../../styles'
import { spacing } from '../../theme'
import { ThemeColors } from '../../theme/colors'
import { FullWidthFlex } from '../layout/Flex'
import Text from '../Text/Text'

type BannerVariant = 'warning' | 'success' | 'error' | 'info'

const EmptyIcon = () => null

const VARIANTS: Record<
  BannerVariant,
  {
    color: keyof ThemeColors
    background: keyof ThemeColors
    Icon: ArcadeIcon<16 | 24 | 32>
    linkStyle: (theme: Theme) => SerializedStyles
    linkColor: keyof ThemeColors
  }
> = {
  success: {
    color: 'bannerTextSuccess',
    background: 'bannerBackgroundSuccess',
    Icon: EmptyIcon,
    linkStyle: getLinkStyle,
    linkColor: 'bannerTextSuccess',
  },
  info: {
    color: 'bannerTextInfo',
    background: 'secondaryButtonBackground',
    Icon: InformationFillIcon,
    linkStyle: getLinkStyle,
    linkColor: 'bannerTextInfo',
  },
  warning: {
    color: 'bannerTextWarn',
    background: 'bannerBackgroundWarn',
    Icon: AlertFillIcon,
    linkStyle: getWarningLinkStyle,
    linkColor: 'bannerTextWarn',
  },
  error: {
    color: 'bannerTextError',
    background: 'bannerBackgroundError',
    Icon: AlertOutlineIcon,
    linkStyle: getErrorLinkStyle,
    linkColor: 'bannerTextError',
  },
}

const Container = styled.div<{ variant: BannerVariant }>`
  display: grid;
  grid-template-columns: auto 1fr;
  padding: ${({ theme }) => theme.spacing.m};
  column-gap: ${spacing.m};
  border-radius: 1rem;
  color: ${({ theme, variant }) => theme.colors[VARIANTS[variant].color]};
  background: ${({ theme, variant }) => theme.colors[VARIANTS[variant].background]};

  a {
    ${({ theme, variant }) => VARIANTS[variant].linkStyle(theme)};
    color: ${({ theme, variant }) => theme.colors[VARIANTS[variant].linkColor]};
    text-decoration: underline;
  }
`

const StatusBanner: React.FC<{
  message: React.ReactNode
  variant: BannerVariant
  title?: React.ReactNode
  /**
   * Omitting will use an icon if defined with the variant;
   * passing `null` will prevent an icon from rendering at all.
   */
  Icon?: ArcadeIcon | null
}> = ({ title, message, variant, Icon }) => {
  const IconComponent = (Icon === null ? EmptyIcon : Icon) ?? VARIANTS[variant].Icon

  return (
    <Container variant={variant} data-testid={`${variant}-status-banner`}>
      <IconComponent data-testid="status-banner-icon" size={24} />
      <FullWidthFlex flexDirection="column" gap={spacing.xs}>
        {title && <Text typography="label">{title}</Text>}
        {message && <Text typography="body">{message}</Text>}
      </FullWidthFlex>
    </Container>
  )
}

export default StatusBanner
